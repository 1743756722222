<template>
  <div class="header_wrapper d-flex flex-column align-items-center">
    <div class="header_titles d-flex flex-wrap justify-content-around">
      <h1 class="header_titles__seo text-nowrap me-3">Фотограф беременности в Краснодаре</h1>
      <h3 class="header_titles__name text-nowrap me-3">Валерия Товкес </h3>
    </div>
    <div id="nav" class="header_nav d-flex flex-row align-items-start flex-nowrap justify-content-between">
      <router-link
        to="/"
        class="me-1 me-md-3"
        :class="{ active: this.$route.meta.headerSection === 'home' }"
      >
          Главная
      </router-link>
      <router-link to="/works/one" class="me-1 me-md-3 route" :class="{ active: this.$route.meta.headerSection === 'works' }">Портфолио</router-link>
      <router-link to="/prices"  class="me-1 me-md-3" :class="{ active: this.$route.meta.headerSection === 'prices' }">Стоимость</router-link>
      <router-link to="/rent/minimalism"  class="text-nowrap me-2" :class="{ active: this.$route.meta.headerSection === 'rent' }">Прокат <span class="d-none d-sm-inline">одежды</span></router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Header'
}
</script>

<style scoped lang="scss">
  @import "~bootstrap/scss/_functions.scss";
  @import "~bootstrap/scss/_variables.scss";
  @import "~bootstrap/scss/mixins/_breakpoints.scss";
@font-face {
  font-family: "Caveat";
  src: local("Caveat"),   url(../fonts/Caveat-Regular.ttf) format("truetype");
  }

  $logo_side: 80px;
  .header_wrapper{
    margin: 40px;
    margin-bottom: 5px;
    text-align: center;
    font-family: Caveat, sans-serif;
    @include media-breakpoint-down(sm) {
      margin: 5px;
    }

    .header_logo{
      width: $logo_side;
      height: $logo_side;
      border-right:  2px solid #d45cd4;
      border-bottom:  2px solid #d45cd4;
      border-radius: 0 0 50px 0;
    }
    .header_titles{
      h1,h2,h3{
        font-family: Caveat, "Helvetica Neue", Helvetica, sans-serif;
      }
      &__seo{
        font-size: 52px;
        @include media-breakpoint-down(sm) {
          font-size: 24px;
        }
      }
      &__name{
        font-size: 52px;
        @include media-breakpoint-down(sm) {
          font-size: 24px;
        }
      }      
    }
    .header_nav{
      width: 50vw;
      @include media-breakpoint-down(lg) {
        width: 95vw;
      }
      @include media-breakpoint-down(md) {
        width: 95vw;
      }
      @include media-breakpoint-down(sm) {
        width: 90vw;
      }
    }
  }
  a {
    color: #253a61;
    font-size: 32px;

    &.active {
      outline: none;
      border-radius: 3px / 100%;
      background-image:
      linear-gradient(45deg, rgba(255,255,255,.0) 30%, rgba(255,255,255,.8), rgba(255,255,255,.0) 70%),
      linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0) 20%, rgba(255,255,255,0) 90%, rgba(255,255,255,.3)),
      linear-gradient(to right, rgba(125,125,125,1), rgba(255,255,255,.9) 45%, rgba(125,125,125,.5)),
      linear-gradient(to right, rgba(125,125,125,1), rgba(255,255,255,.9) 45%, rgba(125,125,125,.5)),
      linear-gradient(to right, rgba(223,190,170,1), rgba(255,255,255,.9) 45%, rgba(223,190,170,.5)),
      linear-gradient(to right, rgba(223,190,170,1), rgba(255,255,255,.9) 45%, rgba(223,190,170,.5));
      background-repeat: no-repeat;
      background-size: 200% 100%, auto, 100% 2px, 100% 2px, 100% 1px, 100% 1px;
      background-position: 200% 0, 0 0, 0 0, 0 100%, 0 4px, 0 calc(100% - 4px);
      box-shadow: rgba(0,0,0,.5) 3px 10px 10px -10px;

      &:hover {
        transition: .5s linear;
        background-position: -200% 0, 0 0, 0 0, 0 100%, 0 4px, 0 calc(100% - 4px);
      }
    }

    &:hover {
      -webkit-transform: scale(1.1, 1.1);
      transform: scale(1.1, 1.1);
      transition: .3s linear;
    }
    
  

    /* links hover effect starts*/
    /* position: relative;
    overflow: hidden;        
  &:after {
    background: rgb(87, 124, 224);
    content: "";
    height: 50px;
    left: -75px;
    opacity: 0.2;
    position: absolute;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: -10;
  }
  &:hover{
    &:after {
      left: 120%;
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }
  } */
  /* links hover effect ends*/

    @include media-breakpoint-down(sm) {
      font-size: 22px;
    }
    &:visited{
      color: #253a61;
    }
    
  }
</style>
