
<template>
  <div class="wear">
    <LightGallery
      :images="images"
      :index="index"
      :disable-scroll="true"
      @close="index = null"
    />
    <ul class="wear_list">
      <li
        class="wear_list__item d-flex flex-column justify-content-start"
        v-for="(thumb, thumbIndex) in thumbs"
        :key="thumbIndex"
        @click="index = thumbIndex"
      >
        <div class="wear_list__item__image"><img :src="thumb.image" ></div>
        <span v-html="thumb.title"> </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { LightGallery } from 'vue-light-gallery';
export default {
  name: 'RentOne',
  metaInfo: {
    title: 'Фотограф беременности Краснодар недорого',
    meta: [
      { vmid: 'description_rent_one', property: 'description', name: 'description', content: 'прокат платьев для беременных краснодар' },
    ],
  },  
    components: {
      LightGallery,
    },
    data() {
      return {
        thumbs: [
            { title:'Боди 42-52 размера', image: require('@/assets/images/wear/1.jpg')},
            { title:'Подходит на любой размер', image: require('@/assets/images/wear/2.jpg')},
            { title:'Подходит на любой размер', image: require('@/assets/images/wear/3.jpg')},
            { title:'Подходит на любой размер', image: require('@/assets/images/wear/4.jpg')},
            { title:'Боди 40-42 размера', image: require('@/assets/images/wear/5.jpg')},
            { title:'Платье 2 в 1 42-46 размера', image: require('@/assets/images/wear/6.jpg')},
            { title:'Платье 48-52 размера', image: require('@/assets/images/wear/7.jpg')},
            { title:'Черная рубашка <br> оверсайз', image: require('@/assets/images/wear/8.jpg')},
            { title:'Боди 40-42 размера', image: require('@/assets/images/wear/9.jpg')},
            { title:'Подходит на любой размер', image: require('@/assets/images/wear/10.jpg')},
            { title:'Подходит на любой размер', image: require('@/assets/images/wear/11.jpg')},
            { title:'Боди 42-46 размера', image: require('@/assets/images/wear/12.jpg')},
           
            { title:'Классический костюм <br> 44-48 размера', image: require('@/assets/images/wear/24.jpg')},
            
          { title:'Платье рыбка <br> 44-48 размер', image: require('@/assets/images/wear/31.jpg')},
          { title: 'Пляжное платье <br> 44-48 размер', image: require('@/assets/images/wear/32.jpg') },
          { title: 'Костюм (укороченный пиджак) <br> 44- 48 размер', image: require('@/assets/images/wear/33.jpg') },
          { title: 'Бархатный боди <br> 44 - 48 размер', image: require('@/assets/images/wear/34.jpg') },
          { title: 'Платье рыбка <br> 46-50 размер', image: require('@/assets/images/wear/35.jpg') },

          { title: 'Воздушное платье <br> до 50 размера', image: require('@/assets/images/wear/36.jpg') },
          { title: 'Боди-водолазка <br> 42-46 размер', image: require('@/assets/images/wear/37.jpg') },
          { title: 'Белая рубашка <br> оверсайз', image: require('@/assets/images/wear/38.jpg') },
          { title: 'Топ бант <br> на любой размер', image: require('@/assets/images/wear/39.jpg') },
          { title: 'Топ бант <br> на любой размер', image: require('@/assets/images/wear/40.jpg') },
          { title: 'Юбка со шлейфом <br> оверсайз', image: require('@/assets/images/wear/41.jpg') },
          { title: 'Шифоновое платье <br> 42-52 размер', image: require('@/assets/images/wear/42.jpg') },
          { title: 'Платье со стразами<br> 42-48 размер', image: require('@/assets/images/wear/43.jpg') },
          { title: 'Юбка с топом <br> 44-48 размер', image: require('@/assets/images/wear/44.jpg') },
          { title: 'Рыбка на одно плечо <br> 44-48 размер', image: require('@/assets/images/wear/45.jpg') }
        ],
        images: [
          { title:'Боди 42-52 размера', url: require('@/assets/images/wear/full/1.jpg') },
          { title:'Подходит на любой размер', url: require('@/assets/images/wear/full/2.jpg') },
          { title:'Подходит на любой размер', url: require('@/assets/images/wear/full/3.jpg') },
          { title:'Подходит на любой размер', url: require('@/assets/images/wear/full/4.jpg') },
          { title:'Боди 40-42 размера', url: require('@/assets/images/wear/full/5.jpg') },
          { title:'Платье 2 в 1 42-46 размера', url: require('@/assets/images/wear/full/6.jpg') },
          { title:'Платье 48-52 размера', url: require('@/assets/images/wear/full/7.jpg') },
          { title:'Подходит на любой размер', url: require('@/assets/images/wear/full/8.jpg') },
          { title:'rrr', url: require('@/assets/images/wear/full/9.jpg') },
          { title:'Подходит на любой размер', url: require('@/assets/images/wear/full/10.jpg') },
          { title:'Подходит на любой размер', url: require('@/assets/images/wear/full/11.jpg') },
          { title:'Боди 42-46 размера', url: require('@/assets/images/wear/full/12.jpg') },

          { title:'Классический костюм 44-48 размера', url: require('@/assets/images/wear/full/24.jpg') },
          
          { title:'Чёрное платье 44-48 размер', url: require('@/assets/images/wear/full/31.jpg') },
          { title: 'Пляжное платье 44-48 размер', url: require('@/assets/images/wear/full/32.jpg') },
          { title: 'Костюм (укороченный пиджак) 44- 48 размер', url: require('@/assets/images/wear/full/33.jpg') },
          { title: 'Бархатный боди 44 - 48 размер', url: require('@/assets/images/wear/full/34.jpg') },
          { title: 'Платье рыбка 46-50 размер', url: require('@/assets/images/wear/full/35.jpg') },
          
          { title: 'Воздушное платье до 50 размера', url: require('@/assets/images/wear/full/36.jpg') },
          { title: 'Боди-водолазка 42-46 размер', url: require('@/assets/images/wear/full/37.jpg') },
          { title: 'Белая рубашка оверсайз', url: require('@/assets/images/wear/full/38.jpg') },
          { title: 'Топ бант красный на любой размер', url: require('@/assets/images/wear/full/39.jpg') },
          { title: 'Топ бант белый на любой размер', url: require('@/assets/images/wear/full/40.jpg') },
          { title: 'Юбка со шлейфом оверсайз', url: require('@/assets/images/wear/full/41.jpg') },
          { title: 'Шифоновое платье 42-52 размер', url: require('@/assets/images/wear/full/42.jpg') },
          { title: 'Платье со стразами 42-48 размер', url: require('@/assets/images/wear/full/43.jpg') },
          { title: 'Юбка с топом 44-48 размер', url: require('@/assets/images/wear/full/44.jpg') },
          { title: 'Рыбка на одно плечо 44-48 размер', url: require('@/assets/images/wear/full/45.jpg') }
        ],
        index: null,
      };
    },
  };
</script>
<style lang="scss">
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

@font-face {
  font-family: "FiraSans";
  src: local("FiraSans"),   url(../fonts/FiraSans-Regular.ttf) format("truetype");
  }

  .wear{
    &_list{
      list-style-type: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 15px;
      @include media-breakpoint-down(md) { 
        flex-direction: column;
      }
      &__item{
        height: 300px;
        max-height: 300px;
        min-height: 200px;
        &__image{
        height: 70%;
        width: auto;        
        img{
          height: 100%;
          width: auto;
          border-radius: 50%;
        }
      }
      }
      
    }
  }

  .rent{
    font-family: FiraSans;
    gap: 20px;
    &_wrapper{
      width: 20%;
      min-width: 350px;
      max-width: 350px;
      @include media-breakpoint-down(lg) { 
        width: 33%;
      }
      @include media-breakpoint-down(md) { 
        width: 45%;
      }
      @include media-breakpoint-down(sm) { 
        width: 95%;
      }
      .image{
        img{
          height: auto;
          width: 100%;
        }
      }
      .text{
        span{

        }
      }
    }
  }
</style>
