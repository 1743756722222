<template>
  <div class="works">
    <!-- <h3>галерея</h3>
    <span>Съемка в студиях с профессиональным светом, фонами, циклорамой, стильная фотосессия беременности!</span> -->
      <div class="works_gallery" id="works_gallery">
        <div 
          v-for="i in items"
          :key="'gallery_'+i"
          class="gallery_items"
        > 
          <template 
            v-if="i == 'Item1' "
            class=""
          >
              <div class="gallery_item">
              <img  
              alt="potograpaher_logo"
              src="../assets/images/gallery/minim/1.jpg"
              >
            </div>
              <div class="gallery_item">
              <img 
              alt="potograpaher_logo"
              src="../assets/images/gallery/minim/2.jpg"
              >
              </div>
              <div class="gallery_item gallery_item__big">
              <img
              alt="potograpaher_logo"
              src="../assets/images/gallery/minim/3.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
              alt="potograpaher_logo"
              src="../assets/images/gallery/minim/4.jpg"
              >
              </div>
              <div class="gallery_item gallery_item__big">
              <img 
              alt="potograpaher_logo"
              src="../assets/images/gallery/minim/5.jpg"
              >
              </div>
              <div class="gallery_item ">
              <img
              alt="potograpaher_logo"
              src="../assets/images/gallery/minim/6.jpg"
              >
              </div>
              <div class="gallery_item ">
              <img
              alt="potograpaher_logo"
              src="../assets/images/gallery/minim/7.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
              alt="potograpaher_logo"
              src="../assets/images/gallery/minim/8.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
              alt="potograpaher_logo"
              src="../assets/images/gallery/minim/9.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/10.jpg"
              >
              </div>
              <div class="gallery_item gallery_item__big">
              <img     
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/11.jpg"
              >
              </div>
              <div class="gallery_item gallery_item__big">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/12.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/13.jpg"
              >
              </div>
              
          </template>
           <template 
            v-if="i == 'Item1' "
            class=""
           >             
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/14.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/15.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/16.jpg"
              >
              </div>
              <div class="gallery_item gallery_item__big">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/17.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/18.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/19.jpg"
              >
              </div>
              <div class="gallery_item ">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/20.jpg"
              >
          </div>
        </template>
        <template 
            v-if="i == 'Item1' "
            class=""
           >             
              <div class="gallery_item ">
              <img     
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/21.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/22.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/23.jpg"
              >
              </div>
              <div class="gallery_item gallery_item__big">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/24.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/25.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/26.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/27.jpg"
              >
              </div>
              <div class="gallery_item ">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/28.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/29.jpg"
              >
              </div>
              <div class="gallery_item">
                <img
                  alt="potograpaher_logo"
                src="../assets/images/gallery/minim/30.jpg"
                >
              </div>
              <div class="gallery_item ">
                <img     
                  alt="potograpaher_logo"
                src="../assets/images/gallery/minim/31.jpg"
                >
              </div>
              <div class="gallery_item">
                <img
                  alt="potograpaher_logo"
                src="../assets/images/gallery/minim/32.jpg"
                >
              </div>
              <div class="gallery_item">
                <img
                  alt="potograpaher_logo"
                src="../assets/images/gallery/minim/48.jpg"
                >
              </div>
              <div class="gallery_item ">
                <img     
                  alt="potograpaher_logo"
                src="../assets/images/gallery/minim/49.jpg"
                >
              </div>
              <div class="gallery_item">
                <img
                  alt="potograpaher_logo"
                src="../assets/images/gallery/minim/50.jpg"
                >
              </div>
        </template>
        <template 
            v-if="i == 'Item1' "
            class=""
           >             
              
              <div class="gallery_item gallery_item__big">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/33.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/34.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/35.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/36.jpg"
              >
              </div>
              <div class="gallery_item ">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/37.jpg"
              >
              </div>
              <div class="gallery_item ">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/38.jpg"
              >
              </div>
              <div class="gallery_item ">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/39.jpg"
              >
              </div>
              <div class="gallery_item ">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/40.jpg"
              >
              </div>
              <div class="gallery_item gallery_item__big">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/41.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/42.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/43.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/44.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/45.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/46.jpg"
              >
              </div>
              <div class="gallery_item">
              <img
                alt="potograpaher_logo"
              src="../assets/images/gallery/minim/47.jpg"
              >
              </div>
        </template>
      </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'WorksOne',
  metaInfo: {
    title: 'Минимализм фото',
    meta: [ 
      { vmid: 'description_works_one', property: 'description', name: 'description', content: 'Фотограф беременных, студийная фотография Краснодар' },
    ],
  }, 
  data() {
    return{
      scrolled: false,
      nextItem: 1,
      items: []    
    }
  },
  mounted(){
    /* const gallery = document.querySelector('.works_gallery');
    gallery.addEventListener('scroll', e => {
      if(gallery.scrollTop + gallery.clientHeight >= gallery.scrollHeight){        
        this.loadMore();
      }
    } ) */

    this.loadMore();
  },
  methods: {
    loadMore(){
      if(this.nextItem < 5){
        setTimeout( e => {        
          for(let i =0; i < 1;  i++){
            this.items.push('Item' + this.nextItem++);
          }
        }, 200);
      }
    }
  }
}
</script>

<style lang="scss">
  .works_gallery{
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .gallery_items{
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    .gallery_item{
      position: relative;
      padding: 0px 0px 85% 0px;
      min-height: 550px;
      img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .gallery_item__big{
      grid-column: span 2;
      grid-row: span 2;
    }
  }
</style>
