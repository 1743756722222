
<template>
  <div class="rent-root">
    <div class="rent-root__menu">
      <router-link
        class="rent-root__menu-element"
        to="/rent/minimalism"
      >
        Для минимализма
      </router-link>
      <router-link
        class="rent-root__menu-element"
        to="/rent/studio"
      >
        Для интерьерной студии
      </router-link>
    </div>
    <div class="rent-root__view">
      <transition name="slide-fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RentRoot',
  /* metaInfo: {
    title: 'Фотограф беременности Краснодар красиво',
    meta: [
      { vmid: 'description_rent_root', property: 'description', name: 'description', content: 'выписка роддом Краснодар' },
    ],
  },  */ 
    components: {
    },
    data() {
      return {
      };
    },
  };
</script>
<style lang="scss">
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

@font-face {
  font-family: "FiraSans";
  src: local("FiraSans"),   url(../fonts/FiraSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Kosugi";
  src: local("Kosugi"),   url(../fonts/KosugiMaru-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Comforter";
  src: local("Comforter"),   url(../fonts/Comforter-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Caveat";
  src: local("Caveat"),   url(../fonts/Caveat-Regular.ttf) format("truetype");
}

  .rent-root{
    font-family: FiraSans;
    display: flex;
    flex-direction: column;
    gap: 15px;

    &__menu {
      flex: 0 0;
      display: flex;
      flex-flow: row nowrap;
      gap: 5px;
      justify-content: space-evenly;

      &-element {
        min-height: 70px;
        padding: 10px;
        border-radius: 10px;
        color: black;
        text-decoration: none;
        background: rgba(230, 162, 242, 0.41);
        min-width: 150px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        letter-spacing: normal;
        font-family: Caveat;
        opacity: 0.5;
        transition: all 0.5s;

        &.router-link-active {
          background: #CCD3F9;
          -webkit-box-shadow: 0px 0px 37px 8px rgba(204,211,249, 0.8);
          -moz-box-shadow: 0px 0px 37px 8px rgba(204,211,249, 0.8);
          box-shadow: 0px 0px 37px 8px rgba(204,211,249, 0.8);
          font-size: 24px;
          opacity: 1;
          transition: all 0.5s;
        }
      }
    }

    &__view {
      flex: 1 0;
    }

    .slide-fade-enter-active {
      transition: all .3s ease;
    }
    .slide-fade-leave-active {
      transition: all .7s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active до версии 2.1.8 */ {
      transform: translateX(20px);
      opacity: 0;
    }
  }
</style>
