<template functional>
  <div class="footer_wrapper ">
    <div class="footer_links d-flex flex-row justify-content-around align-items-center mb-3">
      <div class="footer_links__item"><a href="https://wa.me/message/BHQRXQLEOOZMO1"  target="blanc">WHATSAPP</a></div>
      <div class="footer_links__item"><a href="https://www.instagram.com/valeria_tovkes_foto/" target="blanc">INST*GRAM</a></div>
      <div class="footer_links__item"><a href="https://vk.com/valeria_tovkes_foto" target="blanc">VK</a></div>
    </div>
    <div class="footer_bottom d-flex flex-row justify-content-between align-items-center">
      <section class="footer footer_left">
        <span>Creator VoOneCode</span><br>
        <span>2022</span>
      </section>
      <section class="footer footer_right">
        <span>Делать с удовольствием!</span>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>
>
<style scoped lang="scss">
  @import "~bootstrap/scss/_functions.scss";
  @import "~bootstrap/scss/_variables.scss";
  @import "~bootstrap/scss/mixins/_breakpoints.scss";

@font-face {
  font-family: "Caveat";
  src: local("Caveat"),   url(../fonts/Caveat-Regular.ttf) format("truetype");
  }

  $smth: 80px;
  .footer_wrapper{
    padding: 15px;
    font-family: Caveat, sans-serif;
    @include media-breakpoint-down(sm) {
      padding: 5px;
    }
    .footer_bottom{
      font-size: 18px;
      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
    .footer_left{}
    .footer_right{}
    }
    .footer_links{
      &__item{
        font-size: 30px;
        height: 80px;
        line-height: 80px;
        width: 170px;
        text-align: center;

        //links hover effect starts
        border: 0 solid;
        box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.3);
        outline: 1px solid;
        outline-color: rgba(8, 26, 59, 0.753);
        outline-offset: 0px;
        text-shadow: none;
        transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
        &:hover{
        box-shadow:  0 0 20px 15px rgba(212, 47, 218, 0.567), inset 0 0 0px 0px rgba(16, 54, 221, 0.315);
        outline-color: rgba(8, 26, 59, 0.9);
        outline-offset: 15px;
        text-shadow: 1px 1px 1px #0f313f; 
        }
        //links hover effect ends


        a{
          color: #414042;
          //text-decoration: none;          
        }
        @include media-breakpoint-down(md) {
          font-size: 18px;
          height: 50px;
          line-height: 50px;
          width: 100px;
          text-align: center;
        }
      }
    }    
  }
</style>
