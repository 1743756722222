
<template>
  <div class="wear">
    <LightGallery
      :images="images"
      :index="index"
      :disable-scroll="true"
      @close="index = null"
    />
    <ul class="wear_list">
      <li
        class="wear_list__item d-flex flex-column justify-content-start"
        v-for="(thumb, thumbIndex) in thumbs"
        :key="thumbIndex"
        @click="index = thumbIndex"
      >
        <div class="wear_list__item__image"><img :src="thumb.image" ></div>
        <span v-html="thumb.title"> </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { LightGallery } from 'vue-light-gallery';
export default {
  name: 'RentTwo',
  metaInfo: {
    title: 'Лучший фотограф беременности Краснодар',
    meta: [
      { vmid: 'description_rent_two', property: 'description', name: 'description', content: 'одежда для беременных краснодар' },
    ],
  },  
    components: {
      LightGallery,
    },
    data() {
      return {
        thumbs: [
           
            { title:'Будуарное платье на любой размер', image: require('@/assets/images/wear/13.jpg')},
            { title:'Будуарное платье <br> на 44-48 размера', image: require('@/assets/images/wear/14.jpg')},
            { title:'Платье 42-46 размера', image: require('@/assets/images/wear/15.jpg')},
            { title:'Будуарное платье <br> 44-52 размера', image: require('@/assets/images/wear/16.jpg')},
            { title:'Будуарное платье <br> 42-46 размера', image: require('@/assets/images/wear/17.jpg')},
            { title:'Платье-халат <br> 42-48 размера', image: require('@/assets/images/wear/18.jpg')},
            { title:'Комбинация <br> 42-46 размера', image: require('@/assets/images/wear/19.jpg')},
            { title:'Платье 46-48 размера', image: require('@/assets/images/wear/20.jpg')},
            { title:'Туника 42-46 размер <br> в комплекте гетры, шапка, варежки', image: require('@/assets/images/wear/21.jpg')},
            { title:'Платье-халат <br> Подходит на любой размер', image: require('@/assets/images/wear/22.jpg')},
            { title:'Будуарные платья <br> Женское 44-50,детское 2-4 года', image: require('@/assets/images/wear/23.jpg')},

            { title:'Будуарное платье <br> 42-46 размера', image: require('@/assets/images/wear/25.jpg')},
            { title:'Пижамы <br> Женская 42-48,Мужская 48-52', image: require('@/assets/images/wear/26.jpg')},
            { title:'Пижамы <br> Женская 42-46,Мужская 44-50', image: require('@/assets/images/wear/27.jpg')},
          { title:'Свитшоты <br> Женский 42-48,Мужской 48-52,<br> есть детские', image: require('@/assets/images/wear/28.jpg')},
          { title:'Свитера <br> Женский 42-44,Мужской 46-52,<br> детская на 1год', image: require('@/assets/images/wear/29.jpg')},
          { title:'Пижамы <br> Женская 42-48,Мужская 46-50,<br> детская на 1год', image: require('@/assets/images/wear/30.jpg')},

        ],
        images: [
        
          { title:'Будуарное платье на любой размер', url: require('@/assets/images/wear/full/13.jpg') },
          { title:'Будуарное платье на 44-48 размера', url: require('@/assets/images/wear/full/14.jpg') },
          { title:'Платье 42-46 размера', url: require('@/assets/images/wear/full/15.jpg') },
          { title:'Будуарное платье 44-52 размера', url: require('@/assets/images/wear/full/16.jpg') },
          { title:'Будуарное платье 42-46 размера', url: require('@/assets/images/wear/full/17.jpg') },
          { title:'Платье-халат 42-48 размера', url: require('@/assets/images/wear/full/18.jpg') },
          { title:'Комбинация 42-46 размера', url: require('@/assets/images/wear/full/19.jpg') },
          { title:'Платье 46-48 размера', url: require('@/assets/images/wear/full/20.jpg') },
          { title:'Туника 42-46 размер <br> в комплекте гетры, шапка, варежки', url: require('@/assets/images/wear/full/21.jpg') },
          { title:'Платье-халат Подходит на любой размер', url: require('@/assets/images/wear/full/22.jpg') },
          { title:'Будуарные платья. Женское 44-50,детское 2-4 года', url: require('@/assets/images/wear/full/23.jpg') },

          { title:'Будуарное платье 42-46 размера', url: require('@/assets/images/wear/full/25.jpg') },
          { title:'Пижамы.Женская 42-48,Мужская 48-52', url: require('@/assets/images/wear/full/26.jpg') },
          { title:'Пижамы.Женская 42-46,Мужская 44-50', url: require('@/assets/images/wear/full/27.jpg') },
          { title:'Свитшоты .Женский 42-48,Мужской 48-52', url: require('@/assets/images/wear/full/28.jpg') },
          { title:'Свитера. Женский 42-44,мМужской 46-52', url: require('@/assets/images/wear/full/29.jpg') },
          { title:'Пижамы.Женская 42-48,Мужская 46-50', url: require('@/assets/images/wear/full/30.jpg') },
          
        ],
        index: null,
      };
    },
  };
</script>
<style lang="scss">
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

@font-face {
  font-family: "FiraSans";
  src: local("FiraSans"),   url(../fonts/FiraSans-Regular.ttf) format("truetype");
  }

  .wear{
    &_list{
      list-style-type: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 15px;
      @include media-breakpoint-down(md) { 
        flex-direction: column;
      }
      &__item{
        height: 300px;
        max-height: 300px;
        min-height: 200px;
        &__image{
        height: 70%;
        width: auto;        
        img{
          height: 100%;
          width: auto;
          border-radius: 50%;
        }
      }
      }
      
    }
  }

  .rent{
    font-family: FiraSans;
    gap: 20px;
    &_wrapper{
      width: 20%;
      min-width: 350px;
      max-width: 350px;
      @include media-breakpoint-down(lg) { 
        width: 33%;
      }
      @include media-breakpoint-down(md) { 
        width: 45%;
      }
      @include media-breakpoint-down(sm) { 
        width: 95%;
      }
      .image{
        img{
          height: auto;
          width: 100%;
        }
      }
      .text{
        span{

        }
      }
    }
  }
</style>
